/* ImageDetailsPage.css */

.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #0b1a2a;
    margin-top: -10px;
    background: url('../../assets/images/uploads/background.png') no-repeat;
}

.page-container h1 {
    color: white;
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 10px;
}

.image-card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 1000px;
    width: 100%;
    background-color: #0b1a2a;
    border: 1px solid white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.top-section {
    display: flex;
    width: 100%;
    gap: 20px;
}

.image-section {
    width: 65%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-display {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 8px;
}

.navigation-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
    transition: background 0.3s ease;
}

.navigation-btn:hover {
    background: rgba(0, 0, 0, 0.8);
}

.prev-btn {
    left: 5px;
}

.next-btn {
    right: 5px;
}

.thumbnail-gallery {
    display: flex;
    margin-top: 10px;
    gap: 8px;
    overflow-x: auto;
    padding: 5px;
}

.thumbnail {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border 0.3s ease;
}

.thumbnail.active {
    border: 2px solid blue;
}

.info-section {
    width: 38%;
    padding: 15px;
    border-radius: 8px;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1);
    color: #abb7c4;
    font-weight: 300;
    text-transform: none;
    background-color: #233a50;
}

.info-section h2 {
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.info-list {
    list-style: none;
    padding: 0;
    width: 100%; /* Ensure the info-list occupies the full width */
}

.info-list li {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
}

.contributor {
    font-size: 14px;
    color: whitesmoke;
    margin-top: 0px;
}

.contributor:hover {
    /* color: #dcf836;*/
  }

.description-section {
    width: 61%;
    text-align: left;
    padding: 15px;
    background: #233a50;
    border-radius: 8px;
}

.description-section h2 {
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.map-section {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: #233a50; */
    padding: 0px;
    border-radius: 8px;
    color: white;
}

.map-frame {
    width: 100%;
    height: 350px;
    border: none;
    border-radius: 8px;
}

#mapsource {
     /* pointer-events: none; */
}

.bottom-section {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-top: 3px;
}

.text-blue-500{
    color: white;
}

.text-blue-500:hover{
    color: #dcf836;
}