.ht-footer {
    color: white;
    text-align: center;
    padding: 20px 0;
    font-family: 'Arial', sans-serif;
}

.footer-socials {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.social-links {
    margin: 0 10px;
    font-size: 24px;
    color: white;
    transition: color 0.3s ease;
    display: inline-block; 
}

.social-links:hover {
    color: #dcf836;
}

.footer-nav {
    margin-bottom: 15px;
}

.footer-nav a {
    margin: 0 15px;
    color: white;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
}

.footer-nav a:hover {
    color: #dcf836;
}

.footer-legal {
    font-size: 12px;
    color: white;
}

.footer-legal a {
    
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
}

.footer-legal a:hover {
    color: #dcf836;
}