.hero-button {
    display: flex;
    justify-content: center; 
    width: 100%;
    text-align: center;
}


.hero-button .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;  
    margin-left: -12px
}


.hero-button a {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
    padding: 12px 24px;
    border-radius: 20px;
    text-align: center;
    transition: all 0.3s ease-in-out;
    min-width: 200px; 
    max-width: 200px; 
    height: 50px; 
    display: flex;
    align-items: center;
    justify-content: center;
}


.hero-button .red-button {
    color: white;
    background-color: #dd003f;
    border: 2px solid #dd003f;
}

.hero-button .red-button:hover {
    border-color: white;
}


.hero-button .white-button {
    color: #dd003f;
    background-color: white;
    border: 2px solid transparent;
}

.hero-button .white-button:hover {
    border-color: #dd003f;
    color: #dd003f;
}


@media (max-width: 768px) {
    .hero-button .button-wrapper {
        flex-direction: column;
        gap: 15px;
    }

    .hero-button a {
        min-width: 80%;
        max-width: 250px;
        font-size: 0.9em;
    }
}



.slider .social-link {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
}
.slider .social-link p {
    font-family: 'Dosis', sans-serif;
    font-size: 14px;
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-right: 15px;
}

.slider .hero-slider {
    padding-bottom: 30px;
}
.slider .hero-slider .slick-list {
    margin-top: 30px;
}
@media (max-width: 480px) {
    .slider .hero-slider {
        max-width: 300px;
        margin: 0 auto;
    }
}
@media (max-width: 1024px) {
    .hero-button {
        margin-top: 10px;;
    }
}

.photo-item {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 0px;
}

.photo-item .title-in h6 {
    transition: color 0.3s ease, text-decoration 0.3s ease; 
}


.photo-item:hover .title-in h6 {
    color: #DCF835 !important; 
    
}

.ph-img img {
    width: 200%; 
    height: 400px; 
    object-fit: cover; 
    object-position: center; 
    border-radius: 10px; 
}

.description{
    margin-top: 20px;
}

.description h1 {
    color: white;
}

.description p {
    margin-top: 35px;
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px; 
    line-height: 1.6;
    color: #f0f0f0;
}

.hero .hero-ct {
    padding-top: 10px;
    }