.species-item-style-2 {
    position: relative;
    display: inline-block;
    margin: 15px;
    text-align: center; /* Center the text */
}

.species-item-style-2.species-item-style-1 img {
    width: 400px; 
    height: 200px; 
    object-fit: cover; 
}

.species-item-style-2 img {
    width: 100%; /* Ensure the images fill the grid cells */
    height: auto;
    border-radius: 1px;
}

.mv-item-infor {
    text-align: center;
    margin-top: -5px;
}

.mv-item-infor h6 {
    color: white;
    text-align: center;
    font-size: 14px;
}

.mv-item-infor p {
    color: #f1c40f;  /* Set attribution/login color */
    font-size: 14px;
    margin-top: -10px;
    text-align: center;
}

.species-item-style-2 {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .species-item-style-1 {
    margin-left: 15px;
    max-width: 170px;
  }
}

@media (max-width: 767px) {
  .species-item-style-2 {
    display: flex;
    flex-direction: column;
  }
}
.species-item-style-2 img {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-right: 30px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media (max-width: 767px) {
  .species-item-style-2 img {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .species-item-style-2 .mv-item-infor {
    max-width: 180px;
    margin: 0 auto;
  }
}
.species-item-style-2 .mv-item-infor h6 {
  margin-bottom: 10px;
}
.species-item-style-2 .mv-item-infor h6 a {
  font-family: 'Dosis', sans-serif;
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
}
.species-item-style-2 .mv-item-infor .rate {
  font-size: 12px;
}
.species-item-style-2 .mv-item-infor .rate i {
  color: #f5b50a;
  font-size: 22px;
  margin-right: 5px;
}
.species-item-style-2 .mv-item-infor .rate span {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
}
.species-item-style-2 .mv-item-infor p {
  margin-bottom: 0;
}
.species-item-style-2:hover h6 a {
  color: #dcf836;
}
.species-item-style-1 {
  display: flex;
  flex-direction: column;
  align-items: inherit;
  width: 170px;
  position: relative;
  margin-right: 23px;
}
.species-item-style-1 img {
  margin-right: 0;
  margin-bottom: 15px;
}
.species-item-style-1 .hvr-inner {
  position: absolute;
  top: 0;
  background-color: #dd003f;
  padding: 8px 15px;
  text-align: center;
  margin-left: 30px;
  margin-top: 110px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  cursor: pointer;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media (max-width: 991px) {
  .species-item-style-1 .hvr-inner {
    margin-left: 0;
  }
}
.species-item-style-1 .hvr-inner a {
  font-family: 'Dosis', sans-serif;
  font-size: 14px;
  color: #abb7c4;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
}
.species-item-style-1 .hvr-inner a i {
  margin-right: 5px;
}
.species-item-style-1:hover img {
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  -webkit-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
  filter: alpha(opacity=50);
}
.species-item-style-1:hover .hvr-inner {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
.species-item-style-1:hover h6 a {
  color: #dcf836;
}
@media (max-width: 767px) {
  .species-item-style-1 {
    margin-left: 15px;
    max-width: 170px;
  }
}
