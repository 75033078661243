/* General styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f9;
    color: #333;
    line-height: 1.6;
    margin: 0;
    padding: 0;
}

/* Container */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Header */
h1, h2{
    color: white;
    margin-bottom: 20px;
}

h1 {
    font-size: 2.5em;
    border-bottom: 2px solid #2c3e50;
    padding-bottom: 10px;
}

h2 {
    font-size: 2em;
    border-bottom: 1px solid #2c3e50;
    padding-bottom: 5px;
}

/* Paragraphs */
p {
    margin-bottom: 15px;
}

/* Lists */
ul {
    list-style-type: disc;
    margin-left: 20px;
}

ul li {
    margin-bottom: 10px;
    color: whitesmoke
}

/* Links */
a {
    color: #3498db;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Obfuscate email */
.obfuscate-email {
    color: #e74c3c;
    font-weight: bold;
}

/* Additional styles */
.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    padding: 0 15px;
}

.offset-sm-1 {
    margin-left: 8.333333%;
}

@media (max-width: 768px) {
    .col-sm-10 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .offset-sm-1 {
        margin-left: 0;
    }
}
